package tripper.validation

import tripper.Messages
import tripper.comments.Comment
import tripper.domain.Location
import tripper.domain.WayPoint
import tripper.trips.Trip
import tripper.users.User
import tripper.users.UserRef.Nickname
import tripper.validation.Restrictions.Comments
import tripper.validation.Restrictions.Files
import tripper.validation.Restrictions.Images
import tripper.validation.Restrictions.SearchQuery
import tripper.validation.Restrictions.Trips
import tripper.validation.Restrictions.Users
import tripper.validation.Restrictions.WayPoints

class Validators {
  
  val comment = Validator {
    Comment::text.notEmpty(Messages::inputComment)
    Comment::text.maxLength(Comments.maxLength, Messages::tooLongComment)
  }

  val imageSize = Validator<Number> {
    max(Images.maxSize) { maxImageSize(Images.maxSize) }
  }

  val fileSize = Validator<Number> {
    max(Files.maxSize) { maxFileSize(Files.maxSize) }
  }
  
  val searchQuery = Validator {
    minLength(SearchQuery.minLength) { inputMoreThan(SearchQuery.minLength) }
    maxLength(SearchQuery.maxLength, Messages::tooLongSearchQuery)
  }
  
  val trip = Validator {
    Trip::title.notEmpty(Messages::inputTitle)
    Trip::title.maxLength(Trips.titleMaxLength) { maxTitle(Trips.titleMaxLength) }
    Trip::summary.maxLength(Trips.summaryMaxLength) { maxSummary(Trips.summaryMaxLength) }
    Trip::wayPoints.nonEmpty(Messages::wayPointRequired)
    Trip::wayPoints.maxSize(Trips.wayPointsMaxNumber) { maxWayPoints(Trips.wayPointsMaxNumber) }
    Trip::wayPoints.each {
      WayPoint::name.notEmpty(Messages::inputWayPointName)
      WayPoint::name.maxLength(WayPoints.nameMaxLength) { maxWayPointName(WayPoints.nameMaxLength) }
      WayPoint::since.required(Messages::specifySince)
      WayPoint::until.required(Messages::specifyUntil)
      WayPoint::since.lessThan(WayPoint::until, Messages::sinceMoreThanUntil)
      WayPoint::until.moreThan(WayPoint::since, Messages::untilLessThanSince)
      WayPoint::images.maxSize(WayPoints.imagesMaxNumber) { maxImages(WayPoints.imagesMaxNumber) }
      WayPoint::location.required(Messages::specifyLocation) {
        Location::displayName.notEmpty(Messages::inputLocation)
        Location::displayName.maxLength(WayPoints.locationMaxLength) { maxWayPointLocation(WayPoints.locationMaxLength) }
      }
    }
  }
  
  val user = Validator {
    User::firstName.notEmpty(Messages::inputFirstName)
    User::firstName.maxLength(Users.firstNameMaxLength) { maxFirstName(Users.firstNameMaxLength) }
    User::lastName.notEmpty(Messages::inputLastName)
    User::lastName.maxLength(Users.lastNameMaxLength) { maxLastName(Users.lastNameMaxLength) }
    User::nickname {
      Nickname::value.check({ it != "not-found" }, Messages::unacceptableNickname)
      Nickname::value.maxLength(Users.nickameMaxLength) { maxNickname(Users.nickameMaxLength) }
      Nickname::value.matches(Regex("\\w+"), Messages::invalidNicknameFormat)
    }
    User::about.maxLength(Users.aboutMaxLength) { maxAbout(Users.aboutMaxLength) }
  } 
}