package tripper.components

import androidx.compose.runtime.Composable
import tripper.Modifier
import tripper.tag

@Composable
fun Field(modifier: Modifier = Modifier, content: @Composable () -> Unit) {
  Row(modifier.tag("field")) {
    content()
  }
}