package tripper

import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.css.StyleScope
import org.w3c.dom.HTMLElement

interface AttrsModifier {
  val modifier: AttrsScope<HTMLElement>.() -> Unit get() = {}

  fun modify(attrs: AttrsScope<HTMLElement>) {
    attrs.modifier()
  }
  
  companion object: Modifier
}

fun modifier(modifier: AttrsScope<HTMLElement>.() -> Unit) = object : AttrsModifier {
  override val modifier = modifier
}

fun style(style: StyleScope.() -> Unit) = modifier { style { style() } }

actual typealias Modifier = AttrsModifier

actual fun Modifier.tag(tag: String): Modifier {
  return this + modifier { classes(tag) }
}

actual operator fun Modifier.plus(another: Modifier): Modifier = object : AttrsModifier {
  override val modifier: AttrsScope<HTMLElement>.() -> Unit get() = {
    this@plus.modifier(this)
    another.modifier(this)
  }
}