package tripper.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import kotlinx.datetime.atTime
import org.jetbrains.compose.web.dom.Div
import tripper.*
import tripper.domain.Location
import tripper.domain.WayPoint
import tripper.trips.ImagesViewModel
import tripper.validation.FieldValidationViewModel
import tripper.validation.Restrictions.WayPoints
import tripper.validation.rememberFieldValidation

@Composable
fun WayPointEdit(
  wayPoint: WayPoint,
  setWayPoint: (WayPoint) -> Unit,
  onRemove: () -> Unit = {},
  imagesViewModel: ImagesViewModel = ImagesViewModel.remember(wayPoint.images),
  fieldValidation: FieldValidationViewModel = rememberFieldValidation(),
  modifier: Modifier = Modifier,
  messages: Messages = rememberMessages(),
) = Div({ 
  classes("waypoint")
  modifier.modify(this)
}) {
  GoogleIcon(Icons.Close, modifier {
    classes("remove", "labeled")
    onClick { 
      onRemove()
      fieldValidation.reset()
    }
  }, hint = messages.deleteWayPoint())
  Row(modifier { classes("fields") }) {
    TextField(
      wayPoint.name,
      onValueChange = { setWayPoint(wayPoint.copy(name = it)) },
      validation = fieldValidation.ofField(WayPoint::name),
      label = messages.placeName().markRequired(),
      maxLength = WayPoints.nameMaxLength,
      modifier = modifier { classes("name") }
    )

    val locationValidation = fieldValidation.ofPaths(
      { it[WayPoint::location] },
      { it[WayPoint::location][Location::displayName] }
    )
    val location = wayPoint.location?.displayName.orEmpty()
    remember(location.isNotEmpty()) { if (location.isNotEmpty()) locationValidation.reset() }
    TextField(
      location,
      onValueChange = { setWayPoint(wayPoint.copy(location = wayPoint.location?.copy(displayName = it))) },
      enabled = wayPoint.location != null,
      label = messages.location().markRequired(),
      validation = locationValidation,
      modifier = modifier { classes("location") },
      maxLength = WayPoints.locationMaxLength,
      placeholder = if (wayPoint.location == null) messages.specifyOnMap() else null,
    )

    Row(modifier { classes("period") }) {
      DateField(
        wayPoint.since?.date,
        onValueChange = { setWayPoint(wayPoint.copy(since = it?.atTime(0, 0))) },
        label = messages.startDate().markRequired(),
        validation = fieldValidation.ofField(WayPoint::since),
        max = wayPoint.until?.date,
        modifier = modifier { classes("since") },
      )
      DateField(
        wayPoint.until?.date,
        onValueChange = { setWayPoint(wayPoint.copy(until = it?.atTime(0, 0))) },
        label = messages.endDate().markRequired(),
        validation = fieldValidation.ofField(WayPoint::until),
        min = wayPoint.since?.date,
        modifier = modifier { classes("until") },
      )
    }
  }

  ImageGallery(imagesViewModel, Editable(messages.uploadImage(), maxNumber = WayPoints.imagesMaxNumber))

  Div({ classes("description") }) {
    MarkdownEditor(
      wayPoint.description,
      onChange = { setWayPoint(wayPoint.copy(description = it)) },
      placeholder = messages.describeYourAdventures(),
    )
  }
}