package tripper.components

import androidx.compose.material3.DrawerValue
import androidx.compose.material3.DrawerValue.Closed
import androidx.compose.material3.DrawerValue.Open
import androidx.compose.runtime.*
import io.ktor.http.*
import kotlinx.browser.document
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone.Companion.UTC
import kotlinx.datetime.toKotlinInstant
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.web.attributes.accept
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.max
import org.jetbrains.compose.web.attributes.min
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLElement
import org.w3c.files.File
import org.w3c.files.get
import tripper.*
import tripper.coroutines.SafeCoroutineScope
import tripper.coroutines.rememberCoroutineScope
import tripper.files.FileReaderAdapter
import tripper.files.FileViewModel
import tripper.validation.FieldValidationViewModel
import tripper.validation.ValidationResult
import tripper.validation.ValidationResult.Companion.VALID
import tripper.validation.Validators
import tripper.validation.rememberFieldValidation
import kotlin.js.Date
import kotlin.random.Random

@Composable
actual fun Text(text: String) {
  org.jetbrains.compose.web.dom.Text(text)
}

@Composable
actual fun Column(modifier: AttrsModifier, content: @Composable ColumnScope.() -> Unit) {
  Span({
    modifier.modify(this)
  }) { 
    ElementScopeWrapper(this).content()
  }
}

@Composable
actual fun Row(modifier: AttrsModifier, content: @Composable RowScope.() -> Unit) {
  Div({
    modifier.modify(this)
  }) {
    ElementScopeWrapper(this).content()
  }
}

@Composable
actual fun TextField(
  value: String,
  onValueChange: (String) -> Unit,
  validation: FieldValidationViewModel,
  modifier: AttrsModifier,
  label: String?,
  placeholder: String?,
  singleLine: Boolean,
  minLines: Int,
  maxLength: Int?,
  enabled: Boolean,
  counter: Boolean,
) {
  ValidatedField(
    validation,
    modifier {
      if (label != null) classes("labeled")
    } + modifier,
  ) {
    val id = remember { Random.nextLong().toString() }
    label?.let {
      Label(id) { Text(it) }
    }
    val attributes = modifier {
      id(id)
      if (label != null) classes("labeled")
      placeholder?.let { attr("placeholder", it) }
      maxLength?.let { attr("maxlength", it.toString()) }
      if (!enabled) attr("disabled", "")
      spellCheck(false)
    }
    if (singleLine) {
      TextInput(value) {
        onInput {
          onValueChange(it.value)
          validation.reset()
        }
        attributes.modify(this)
      }
    } else {
      MultilineInput(
        value,
        onValueChange = {
          onValueChange(it)
          validation.reset()
        },
        attributes,
        minLines
      )
    }
    if (maxLength != null && counter) Column(modifier { classes("counter") }) {
      Text("${value.length} ⁄ $maxLength")
    }
  }
}

@Composable
actual fun DateField(
  value: LocalDate?,
  onValueChange: (LocalDate?) -> Unit,
  validation: FieldValidationViewModel,
  modifier: Modifier,
  label: String?,
  enabled: Boolean,
  min: LocalDate?,
  max: LocalDate?,
) {
  ValidatedField(
    validation, 
    modifier {
      if (label != null) classes("labeled")
    } + modifier,
  ) {
    val id = remember { Random.nextLong().toString() }
    label?.let {
      Label(id) { Text(it) }
    }
    DateInput(value?.toString().orEmpty()) {
      id(id)
      if (label != null) classes("labeled")
      if (!enabled) disabled()
      if (min != null) min(min.toString())
      if (max != null) max(max.toString())
      onInput {
        onValueChange((it.target.valueAsDate as Date?)?.toKotlinInstant()?.toLocalDateTime(UTC)?.date)
        validation.reset()
      }
    }
  }
}

@Composable
fun FileField(
  label: String,
  viewModel: FileViewModel,
  accept: ContentType = ContentType.Any,
  validateFile: Validators.(File) -> ValidationResult = { VALID },
  modifier: Modifier = Modifier,
  messages: Messages = rememberMessages(),
  fileReader: FileReaderAdapter = remember { FileReaderAdapter(10 * 1024 * 1024, messages) },
  scope: SafeCoroutineScope = rememberCoroutineScope(),
  random: Random = Random,
) {
  val fieldValidation = rememberFieldValidation()
  ValidatedField(fieldValidation, modifier { classes("upload-file") }) {
    val id = remember { random.nextLong().toString() }
    Label(id, { modifier.modify(this) }) { Text(label) }
    FileInput {
      id(id)
      hidden()
      accept(accept.toString())
      onChange {
        val file = it.target.files?.get(0) ?: return@onChange
        fieldValidation.validate { validateFile(file) }

        scope.launch { 
          val fileBytes = fileReader.read(file)
          viewModel.upload(fileBytes, file.name, file.type) 
        }
      }
    }
  }
}

@Composable
fun ValidatedField(
  validation: FieldValidationViewModel,
  modifier: Modifier = Modifier,
  messages: Messages = rememberMessages(),
  content: @Composable () -> Unit,
) {
  val validationResults by validation.validationResults.collectAsState()
  val isInvalid = validationResults.any { it.isInvalid }
  Field(modifier {
    if (isInvalid) classes("invalid")
  } + modifier) {
    content()

    Row(modifier { classes("supporting-text") }) {
      if (isInvalid) Column(modifier { classes("error") }) {
        Text(validationResults.flatMap { it.messages }.first()(messages))
      }
    }
  }
}

@Composable
actual fun PageTitle(title: String) {
  DisposableEffect(title) {
    document.title = title
    onDispose {  }
  }
}

@Composable
actual fun ModalNavigationDrawer(
  drawerContent: @Composable () -> Unit,
  drawerState: DrawerState,
  modifier: Modifier,
  scope: SafeCoroutineScope,
  content: @Composable () -> Unit,
) {
  Column(modifier { classes("navigation-drawer") } + modifier) {
    if (drawerState.isClosed) {
      content()
    } else if (drawerState.isOpen) {
      Overlay(onClick = {
        scope.launch { drawerState.close() }
      }, onDismissRequest = {
        scope.launch { drawerState.close() }
      }) {
        drawerContent()
      }
    }
  }
}

@Composable
actual fun ModalDrawerSheet(
  modifier: Modifier,
  content: @Composable () -> Unit,
) {
  Column(modifier { classes("drawer-sheet") } + modifier) {
    content()
  }
}

@Composable
actual fun NavigationDrawerItem(
  label: @Composable () -> Unit,
  selected: Boolean,
  onClick: () -> Unit,
  modifier: Modifier,
  icon: (@Composable () -> Unit)?,
) {
  Row(modifier { 
    classes("drawer-item")
    attr("selected", selected.toString())
    onClick { onClick() }
  } + modifier) { 
    icon?.let { it() } 
    label()
  }
}

actual class DrawerState(initial: DrawerValue) {
  private var value by mutableStateOf(initial)
  
  actual val isOpen: Boolean get() = value == Open
  actual val isClosed: Boolean get() = value == Closed
  
  actual suspend fun open() {
    value = Open
  }
  actual suspend fun close() {
    value = Closed
  }
}

@Composable
actual fun rememberDrawerState(initialValue: DrawerValue): DrawerState = remember { DrawerState(initialValue) }

class ElementScopeWrapper(private val elementScope: ElementScope<HTMLElement>): JsElementScope, ElementScope<HTMLElement> by elementScope

interface JsElementScope: ElementScope<HTMLElement> 
actual typealias ColumnScope = JsElementScope
actual typealias RowScope = JsElementScope