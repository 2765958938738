package tripper.users

import androidx.compose.runtime.Composable
import tripper.Modifier
import tripper.components.Avatar
import tripper.components.Column
import tripper.components.Row
import tripper.components.Text
import tripper.followings.FollowButton
import tripper.modifier
import tripper.plus

@Composable
fun ProfileBody(user: User, modifier: Modifier = Modifier, showAbout: Boolean = true) {
  Column(modifier { classes("profile-body") } + modifier) {
    Avatar(user)
    Row(modifier { classes("full-name") }) {
      Text("${user.firstName} ${user.lastName}")
    }
    Row(modifier { classes("nickname") }) {
      Text("@${user.ref}")
    }
    FollowButton(user)
    if (showAbout) {
      user.about?.let {
        Row(modifier { classes("about") }) {
          Text(it)
        }
      }
    }
  }
}