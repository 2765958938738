package tripper.components

import androidx.compose.runtime.*
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.rows
import org.jetbrains.compose.web.dom.TextArea
import org.w3c.dom.HTMLTextAreaElement
import org.w3c.dom.events.Event
import tripper.Modifier

@Composable
fun MultilineInput(
  value: String,
  onValueChange: (String) -> Unit,
  modifier: Modifier = Modifier,
  minLines: Int = 1,
) {
  var input by remember { mutableStateOf<HTMLTextAreaElement?>(null) }
  TextArea(value) {
    rows(minLines)
    ref {
      input = it
      onDispose { }
    }
    onInput {
      val borderHeight = 2
      it.target.style.height = "auto"
      it.target.style.height = "${it.target.scrollHeight + borderHeight}px"
      onValueChange(it.value)
    }
    modifier.modify(this)
  }
  DisposableEffect(value.isEmpty()) {
    input?.value = value
    input?.dispatchEvent(Event("input"))
    onDispose { }
  }
  DisposableEffect(Unit) {
    val onResize: (Event) -> Unit = {
      input?.dispatchEvent(Event("input"))
    }
    window.addEventListener("resize", onResize)
    onDispose { window.removeEventListener("resize", onResize) }
  }
}