package tripper.likes

import androidx.compose.runtime.*
import tripper.Modifier
import tripper.comments.ContentId
import tripper.components.GoogleIcon
import tripper.components.Icons
import tripper.modifier
import tripper.pressAnimation
import tripper.untilLoaded
import tripper.users.User

@Composable
fun LikeButton(
  contentId: ContentId,
  self: User,
  modifier: Modifier = Modifier,
  viewModel: LikeViewModel = LikeViewModel.remember(contentId, self.id),
) {
  val likeLoading by viewModel.like.collectAsState()
  val like = likeLoading.untilLoaded(null)
  val (likePressed, setLikePressed) = remember { mutableStateOf(false) }
  GoogleIcon(Icons.Favorite, modifier {
    pressAnimation(likePressed, setLikePressed)
    modifier.modify(this)
    attr("filled", (like != null).toString())

    onClick {
      if (like == null) {
        viewModel.likeContent()
      } else {
        viewModel.dislike()
      }
    }
  })
}