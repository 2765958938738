package tripper.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Label
import tripper.Modifier
import tripper.modifier

@Composable
fun Section(
  modifier: Modifier = Modifier,
  label: String? = null,
  content: @Composable () -> Unit,
) {
  org.jetbrains.compose.web.dom.Section({
    modifier.modify(this)
  }) {
    if (label != null) {
      Label { Text(label) }
    }
    content()
  }
}

@Composable
fun Section(modifier: Modifier = Modifier, tabs: List<@Composable () -> Unit>, content: @Composable () -> Unit) {
  org.jetbrains.compose.web.dom.Section({
    modifier.modify(this)
  }) {
    if (tabs.isNotEmpty()) {
      Row(modifier { classes("tabs") }) {
        tabs.forEach { tab -> tab() }
      }
    }

    content()
  }
}

@Composable
fun Tab(active: Boolean = false, onClick: () -> Unit = {}, content: @Composable () -> Unit) {
  Column(modifier { 
    classes("tab")
    if (active) classes("active")
    onClick { onClick() }
  }) {
    content()
  }
}