package tripper.favorites

import androidx.compose.runtime.*
import tripper.Modifier
import tripper.comments.ContentId
import tripper.components.GoogleIcon
import tripper.components.Icons
import tripper.modifier
import tripper.pressAnimation
import tripper.untilLoaded
import tripper.users.User

@Composable
fun FavoriteButton(
  contentId: ContentId,
  self: User,
  modifier: Modifier = Modifier,
  viewModel: FavoriteViewModel = FavoriteViewModel.remember(contentId, self.id)
) {
  val favoriteLoading by viewModel.favorite.collectAsState()
  val favorite = favoriteLoading.untilLoaded(null)
  val (favoritePressed, setFavoritePressed) = remember { mutableStateOf(false) }
  GoogleIcon(Icons.Star, modifier {
    pressAnimation(favoritePressed, setFavoritePressed)
    modifier.modify(this)
    attr("filled", (favorite != null).toString())

    onClick {
      if (favorite == null) {
        viewModel.addToFavorite()
      } else {
        viewModel.removeFromFavorite()
      }
    }
  })
}