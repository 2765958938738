@file:Suppress("INVISIBLE_MEMBER", "INVISIBLE_REFERENCE")

package tripper.components

import androidx.compose.material3.internal.DropdownMenuPositionProvider
import androidx.compose.runtime.Composable
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.DpOffset
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.unit.LayoutDirection.Ltr
import org.jetbrains.compose.web.dom.Li
import tripper.Modifier
import tripper.modifier
import tripper.plus
import tripper.tag

@Composable
fun DropdownMenu(
  expanded: Boolean = false,
  onDismissRequest: () -> Unit,
  offset: DpOffset = DpOffset.Zero,
  modifier: Modifier = Modifier,
  layoutDirection: LayoutDirection = Ltr,
  content: @Composable () -> Unit,
) {
  if (!expanded) return
  Popup(
    onClick = onDismissRequest,
    positionProvider = DropdownMenuPositionProvider(offset, Density(1.0f)),
    layoutDirection = layoutDirection,
    modifier = modifier.tag("dropdown-menu"),
  ) {
    content()
  }
}

@Composable
fun DropdownMenuItem(
  modifier: Modifier = Modifier,
  enabled: Boolean = false,
  link: String? = null,
  content: @Composable () -> Unit,
) {
  val itemModifier = modifier {
    classes("item")
    attr("enabled", enabled.toString())
  } + modifier
  if (link != null) {
    Link(link, itemModifier) {
      content()
    }
  } else {
    Li({ itemModifier.modify(this) }) {
      content()
    }
  }
}