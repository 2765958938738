package tripper.validation

import tripper.Messages
import tripper.validation.ConstraintViolation.*
import kotlin.reflect.KClass
import kotlin.reflect.KProperty1

class Validator<T>(private val validations: List<(ValidationResult, T) -> ValidationResult>) {
  
  operator fun invoke(instance: T): ValidationResult {
    return validations.fold(ValidationResult()) { acc, validation -> validation(acc, instance) }
  }
  
  companion object {
    inline operator fun <reified T> invoke(build: ValidationBuilder<T>.() -> Unit): Validator<T> {
      val builder = ValidationBuilder<T>(T::class).apply(build)
      return Validator(builder.validations)
    }
  }
}

class ValidationBuilder<T>(val ownerClass: KClass<*>) {
  val validations = ArrayList<(ValidationResult, T) -> ValidationResult>()

  fun KProperty1<T, String?>.maxLength(length: Int, message: Message) {
    val spec = PropertySpec(ownerClass, name)
    validations += { result, instance ->
      val value = get(instance)
      if (value != null && value.length > length) result.addViolation(spec, MaxLength(message)) else result 
    }
  }

  fun KProperty1<T, String?>.matches(regex: Regex, message: Message) {
    val spec = PropertySpec(ownerClass, name)
    validations += { result, instance ->
      val value = get(instance)
      if (value != null && !value.matches(regex)) result.addViolation(spec, NotMatches(message)) else result
    }
  }

  fun <V> KProperty1<T, V>.check(predicate: (V) -> Boolean, message: Message) {
    val spec = PropertySpec(ownerClass, name)
    validations += { result, instance ->
      val value = get(instance)
      if (value != null && !predicate(value)) result.addViolation(spec, NotMatches(message)) else result
    }
  }

  inline fun <reified R : Any> KProperty1<T, R?>.required(
    noinline message: Message,
    noinline build: ValidationBuilder<R>.() -> Unit = {},
  ) {
    val spec = PropertySpec(ownerClass, name)
    val validator = Validator(build)
    validations += { result, instance ->
      val value = get(instance)
      if (value == null) result.addViolation(spec, Required(message))
      else result.addChild(spec, validator(value))
    }
  }

  fun KProperty1<T, String>.notEmpty(message: Message) {
    val spec = PropertySpec(ownerClass, name)
    validations += { result, instance -> if (get(instance).trim().isEmpty()) result.addViolation(spec, Empty(message)) else result }
  }

  fun KProperty1<T, Collection<*>>.nonEmpty(message: Message) {
    val spec = PropertySpec(ownerClass, name)
    validations += { result, instance -> if (get(instance).isEmpty()) result.addViolation(spec, Empty(message)) else result }
  }

  fun KProperty1<T, Collection<*>>.maxSize(length: Int, message: Message) {
    val spec = PropertySpec(ownerClass, name)
    validations += { result, instance -> if (get(instance).size > length) result.addViolation(spec, MaxLength(message)) else result }
  }

  fun <R: Comparable<R>> KProperty1<T, R?>.lessThan(property: KProperty1<T, R?>, message: Message) {
    val spec = PropertySpec(ownerClass, name)
    validations += { result, instance ->
      val first = get(instance)
      val second = property.get(instance)
      if (first != null && second != null && first > second) result.addViolation(spec, LessThan(message))
      else result
    }
  }

  fun <R: Comparable<R>> KProperty1<T, R?>.moreThan(property: KProperty1<T, R?>, message: Message) {
    val spec = PropertySpec(ownerClass, name)
    validations += { result, instance ->
      val first = get(instance)
      val second = property.get(instance)
      if (first != null && second != null && first < second) result.addViolation(spec, MoreThan(message))
      else result
    }
  }

  inline fun <reified R> KProperty1<T, Collection<R>>.each(crossinline build: ValidationBuilder<R>.() -> Unit = {}) {
    val validator = Validator(build)
    val property = PropertySpec(ownerClass, name)
    validations += { result, instance -> result.setChildren(property, get(instance).map { validator(it) }) }
  }

  inline operator fun <reified R : Any> KProperty1<T, R?>.invoke(crossinline build: ValidationBuilder<R>.() -> Unit) {
    val property = PropertySpec(ownerClass, name)
    validations += { result, instance ->
      val value = get(instance)
      if (value != null) result.addChild(property, Validator(build).invoke(value))
      else result
    }
  }
}

inline fun <reified T: Number> ValidationBuilder<T>.max(max: Number, noinline message: Message) {
  val spec = instanceSpec<T>()
  validations += { result, instance -> if (instance.toLong() > max.toLong()) result.addViolation(spec, TooBig(message)) else result }
}

fun ValidationBuilder<String>.minLength(length: Int, message: Message) {
  val spec = instanceSpec<String>()
  validations += { result, instance -> if (instance.length < length) result.addViolation(spec, MinLength(message)) else result }
}

inline fun <reified T: Any> ValidationBuilder<T?>.required(
  noinline message: Message,
  noinline build: ValidationBuilder<T>.() -> Unit = {},
) {
  val spec = instanceSpec<T?>()
  val validator = Validator(build)
  validations += { result, instance ->
    if (instance == null) result.addViolation(spec, Required(message))
    else result.addChild(spec, validator(instance))
  }
}

fun ValidationBuilder<String>.maxLength(length: Int, message: Message) {
  val spec = instanceSpec<String>()
  validations += { result, instance -> if (instance.length > length) result.addViolation(spec, MaxLength(message)) else result }
}

sealed interface ConstraintViolation {
  val message: Message
    
  class Required(override val message: Message): ConstraintViolation {
    override fun toString() = "Required($message)"
  }
  class MaxLength(override val message: Message): ConstraintViolation {
    override fun toString() = "MaxLength($message)"
  }
  class MinLength(override val message: Message): ConstraintViolation {
    override fun toString() = "MinLength($message)"
  }
  class Empty(override val message: Message): ConstraintViolation {
    override fun toString() = "Empty($message)"
  }
  class TooBig(override val message: Message): ConstraintViolation {
    override fun toString() = "TooBig($message)"
  }
  class LessThan(override val message: Message): ConstraintViolation {
    override fun toString() = "LessThan($message)"
  }
  class MoreThan(override val message: Message): ConstraintViolation {
    override fun toString() = "MoreThan($message)"
  }
  class NotMatches(override val message: Message): ConstraintViolation {
    override fun toString() = "NotMatches($message)"
  }
  class AlreadyExists(override val message: Message): ConstraintViolation {
    override fun toString() = "AlreadyExists($message)"
  }
}

typealias Message = Messages.() -> String

data class ValidationResult(
  private val childResultsTree: Map<PropertySpec, ValidationResult> = HashMap(),
  private val childResults: List<ValidationResult> = ArrayList(),
  private val violations: Set<ConstraintViolation> = HashSet(),
) {
  constructor(violation: ConstraintViolation): this(violations = setOf(violation))
  
  private val allViolations: Set<ConstraintViolation> by lazy { 
    violations + childResultsTree.values.flatMap { it.allViolations } + childResults.flatMap { it.allViolations } 
  }
  val isInvalid: Boolean by lazy { allViolations.isNotEmpty() }
  val messages: List<Message> by lazy { allViolations.map { it.message } }

  operator fun get(property: PropertySpec) = childResultsTree.getOrElse(property, ::VALID)
  inline operator fun <reified P> get(property: KProperty1<P, *>) = get(property.spec())
  operator fun get(index: Int) = childResults.getOrElse(index) { VALID }

  operator fun minus(resultViews: List<ValidationResult>): ValidationResult {
    if (this in resultViews) return VALID
    val results = violations - resultViews.flatMap { it.violations }.toSet()
    val resultsTree =  childResultsTree.filterValues { it !in resultViews }.mapValues { (_, resultView) -> resultView - resultViews }
    val resultsList = childResults.filter { it !in resultViews }.map { it - resultViews }
    return copy(childResultsTree = resultsTree, childResults = resultsList, violations = results)
  }
  
  operator fun minus(resultView: ValidationResult): ValidationResult = minus(listOf(resultView))
  
  fun addViolation(property: PropertySpec, violation: ConstraintViolation): ValidationResult {
    val child = get(property)
    val mergedChild = child.copy(violations = child.violations + violation)
    return ValidationResult(childResultsTree + (property to mergedChild), childResults, violations)
  }
  
  fun addChild(property: PropertySpec, child: ValidationResult): ValidationResult {
    val currentChild = get(property)
    val mergedChild = currentChild.merge(child)
    return ValidationResult(childResultsTree + (property to mergedChild), childResults, violations)
  }

  fun setChildren(property: PropertySpec, children: List<ValidationResult>): ValidationResult {
    val currentChild = get(property)
    val mergedChild = currentChild.copy(childResults = children)
    return ValidationResult(childResultsTree + (property to mergedChild), childResults, violations)
  }

  fun merge(another: ValidationResult): ValidationResult {
    val childResultsTree = another.childResultsTree + childResultsTree.mapValues { (property, result) -> result.merge(another[property]) }
    val childResults = another.childResults + childResults.mapIndexed { index, result -> another[index].merge(result) }
    return copy(childResultsTree, childResults, violations + another.violations)
  }

  companion object {
    val VALID = ValidationResult()
  }
}

data class PropertySpec(val ownerClass: KClass<*>, val name: String) {
  override fun toString(): String = "${ownerClass.simpleName}.$name"
}

inline fun <reified T> KProperty1<T, *>.spec() = PropertySpec(T::class, name)
inline fun <reified T> instanceSpec() = instanceSpec(T::class)
fun instanceSpec(instance: Any) = instanceSpec(instance::class)
fun instanceSpec(clazz: KClass<*>) = PropertySpec(clazz, "\$instance\$")