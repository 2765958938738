package tripper.users

import androidx.compose.runtime.*
import io.ktor.http.ContentType.*
import org.jetbrains.compose.web.dom.Button
import tripper.*
import tripper.components.*
import tripper.navigation.Pages
import tripper.trips.Trips
import tripper.trips.TripsViewModel
import tripper.users.TripsTab.AuthorTrips
import tripper.users.TripsTab.Favorites
import tripper.users.UserRef.Nickname
import tripper.validation.ConstraintViolation.AlreadyExists
import tripper.validation.FieldValidationViewModel
import tripper.validation.Message
import tripper.validation.Restrictions.Users
import tripper.validation.ValidationResult
import tripper.validation.rememberFieldValidation
import tripper.validation.spec

@Composable
fun Profile(
  userRef: UserRef,
  viewModel: ProfileViewModel = ProfileViewModel.create(userRef),
  createTripsViewModel: @Composable (UserRef.Id?, Boolean) -> TripsViewModel = { authorId, favorites -> TripsViewModel.remember(favorites, authorId = authorId) },
  rememberAvatarViewModel: @Composable (User) -> AvatarViewModel = { viewModel.avatarViewModel(it) },
  messages: Messages = rememberMessages(),
  pages: Pages = LocalDependencies.current.pages,
) = Column(modifier { classes("profile-page") }) {
  val (userLoading) = viewModel.user.collectAsState()
  val user = userLoading.untilLoaded { return@Column }
  val selfLoading = rememberSelfLoading()
  val self = selfLoading.untilLoaded(null)
  Section(modifier { classes("profile") }) {
    pages.profile.inlinePath(user)
    Card(modifier { classes("profile-card") }) {
      val edit by viewModel.edit.collectAsState()
      if (edit) {
        val validation = rememberFieldValidation()
        val nicknameTaken by viewModel.nicknameTaken.collectAsState()
        EditProfile(user, viewModel::setUser, nicknameTaken, onBack = { viewModel.setEdit(false) }, validation, rememberAvatarViewModel)
        Button({
          classes("save", "primary")
          onClick {
            validation.validate { user(user) }
            viewModel.save(user)
          }
        }) {
          Text(messages.save())
        }
      } else {
        ProfileBody(user)
        if (user.id == self?.id) {
          Row(modifier { classes("controls") }) {
            Link(pages.createTrip.path(), modifier { classes("secondary") }) {
              Text(messages.newTrip())
            }
            Button({
              classes("primary")
              onClick { viewModel.setEdit(true) }
            }) {
              Text(messages.edit())
            }
          }
        }
      }
    }
  }
  
  var activeTab by remember { mutableStateOf<TripsTab>(AuthorTrips(user.id)) }
  Trips(
    viewModel = createTripsViewModel(activeTab.authorId, activeTab.favorites),
    tabs = buildList {
      add {
        Tab(active = activeTab is AuthorTrips, onClick = { activeTab = AuthorTrips(user.id) }) {
          Text(messages.trips())
        }
      }
      if (user.id == self?.id) add {
        Tab(active = activeTab is Favorites, onClick = { activeTab = Favorites }) {
          Text(messages.favorites())
        }
      }
    }
  )
}

private sealed class TripsTab(val authorId: UserRef.Id? = null, val favorites: Boolean = false) {
  data class AuthorTrips(val id: UserRef.Id): TripsTab(authorId = id)
  data object Favorites: TripsTab(favorites = true)
}

@Composable
private fun ProfileViewModel.avatarViewModel(user: User): AvatarViewModel {
  val currentUser by rememberUpdatedState(user)
  return AvatarViewModel.remember(user.avatarUrl, avatarLoading, onUpload = { newAvatar ->
    setUser(currentUser.copy(avatarUrl = newAvatar))
  })
}

@Composable
private fun EditProfile(
  user: User,
  setUser: (User) -> Unit,
  nicknameTaken: Loading<Boolean>,
  onBack: () -> Unit,
  validation: FieldValidationViewModel,
  rememberAvatarViewModel: @Composable (User) -> AvatarViewModel,
  messages: Messages = rememberMessages(),
) = Column(modifier { classes("profile-body") }) {
  val avatarViewModel = rememberAvatarViewModel(user)
  val avatarUser = rememberPrev(user, shouldUpdate = { it.firstName.isNotEmpty() && it.lastName.isNotEmpty() })
  val avatarUrl by avatarViewModel.avatarUrl.collectAsState()
  
  GoogleIcon(Icons.ArrowLeftAlt, modifier {
    classes("back")
    onClick { onBack() }
  })
  Avatar(avatarUrl, avatarUser.initials())
  FileField(
    label = messages.uploadAvatar(),
    viewModel = avatarViewModel,
    accept = Image.Any,
    validateFile = { imageSize(it.size) },
    modifier = modifier { classes("secondary") },
  )
  TextField(
    user.firstName,
    onValueChange = { setUser(user.copy(firstName = it)) },
    label = messages.firstName().markRequired(),
    validation = validation.ofField(User::firstName),
    modifier = modifier { classes("first-name") },
    maxLength = Users.firstNameMaxLength,
  )
  TextField(
    user.lastName,
    onValueChange = { setUser(user.copy(lastName = it)) },
    label = messages.lastName().markRequired(),
    validation = validation.ofField(User::lastName),
    modifier = modifier { classes("last-name") },
    maxLength = Users.lastNameMaxLength,
  )
  val nicknameValidation = validation.ofField(User::nickname)
  remember(nicknameTaken) { 
    if (nicknameTaken is Loading.Finished) {
      nicknameValidation.validateIsNicknameTaken(nicknameTaken.value) { messages.nicknameTaken() }
    }
  }
  TextField(
    user.nickname?.value.orEmpty(),
    onValueChange = { setUser(user.copy(nickname = it.ifEmpty { null }?.let(::Nickname))) },
    label = messages.nickname(),
    validation = nicknameValidation,
    modifier = modifier { classes("nickname") },
    maxLength = Users.nickameMaxLength,
    placeholder = user.id.toString(),
  )
  TextField(
    user.about.orEmpty(),
    onValueChange = { setUser(user.copy(about = it)) },
    label = messages.about(),
    validation = validation.ofField(User::about),
    modifier = modifier { classes("about") },
    singleLine = false,
    minLines = 2,
    maxLength = Users.aboutMaxLength,
  )
}

private fun FieldValidationViewModel.validateIsNicknameTaken(nicknameTaken: Boolean, message: Message) {
  if (nicknameTaken) {
    setValidationResult(ValidationResult(mapOf(User::nickname.spec() to ValidationResult(AlreadyExists(message)))))
  }
}